export const ROUTE_HOME = {
  path: '/',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_ATENDIMENTO = {
  path: '/atendimento',
  name: 'atendimento',
};

export const ROUTE_PRODUTO_FOOD = {
  path: '/produto-food',
  name: 'produto-food',
};

export const ROUTE_PRODUTO = {
  path: '/produto',
  name: 'produto',
};

export const ROUTE_FOOD = {
  path: '/food',
  name: 'food',
};

export const ROUTE_SCAN = {
  path: '/scan',
  name: 'scan',
};

export const ROUTE_VALOR = {
  path: '/valor',
  name: 'valor',
};
