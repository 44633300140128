import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import users from './modules/users';
import cliente from './modules/cliente';
import empresa from './modules/empresa';
import prevenda from './modules/prevenda';
import prevendaitem from './modules/prevendaitem';
import produto from './modules/produto';
import formapagto from './modules/formapagto';
import grupo from './modules/grupo';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('auth/$_setup');
    },
  },

  modules: {
    auth,
    users,
    cliente,
    empresa,
    prevenda,
    prevendaitem,
    produto,
    formapagto,
    grupo,
  },
});
