import apiempresaweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get('prevendaitem', { params: { ...where, ...params } }),
      ]);

      return data;
    },

    async findDetalhado(_, id) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get(`prevendaitem/detalhado/${id}`),
      ]);

      return data;
    },

    async create(_, body) {
      const [{ data }] = await Promise.all([
        apiempresaweb.post('prevendaitem/app', body),
      ]);

      return data;
    },

    async createAll(_, body) {
      const [{ data }] = await Promise.all([
        apiempresaweb.post('prevendaitem/all', body),
      ]);

      return data;
    },

    async update(_, body) {
      const [{ data }] = await Promise.all([
        apiempresaweb.put(`prevendaitem/app/${body.id}`, body),
      ]);

      return data;
    },

    async delete(_, body) {
      const [{ data }] = await Promise.all([
        apiempresaweb.delete(`prevendaitem/app/${body.id}`, body),
      ]);

      return data;
    },

    async deletePorEmpresa(_, body) {
      const [{ data }] = await Promise.all([
        apiempresaweb.delete(`prevendaitem/empresa/${body.id}`, body),
      ]);

      return data;
    },

    async count(_, { id }) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get(`prevendaitem/count/${id}`),
      ]);

      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
